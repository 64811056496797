import MessageMap from '../i18n.d';

const esES: MessageMap = {
  test: {
    translate: 'Traduzir',
    missing: 'Disponível apenas a opção em inglês'
  },
  breadcrumb: {
    home: 'Lista de reglas',
    create: 'Crear regla',
    earning: 'Earning',
    exclusion: 'Ganancias'
  },
  buttons: {
    createNewRuleBtn: 'Crear regla',
    goToAudiencesBtn: 'Ir a Audiencias',
    back: 'Volver atrás',
    continue: 'Continuar'
  },
  rules: {
    title: 'Lista de reglas',
    earningName: '{point} points for every {amountSpent} spent',
    earningList: 'Lista de ganancias',
    earningRulesDescription:
      'Estas reglas permiten que todos los POC registrados en el {tier} ganen puntos por compras elegibles.',
    exclusionList: 'Lista de exclusiones',
    exclusionListDescription:
      'Estas reglas evitan que los POC acumulen puntos a través de las reglas de ganancia, a la vez que los mantienen en el {tier}.'
  },
  tablesRules: {
    searchByDescription: 'Buscar por descripción',
    ruleDescription: 'Descripción de reglas',
    editedOn: 'Editado el',
    status: 'Estado',
    draft: 'Borrador',
    active: 'Activo',
    audience: 'Audiencia',
    audienceName: 'Nombre de la audiencia',
    estimatedPOCsTargeted: 'POC objetivo estimados'
  },
  earningRulesTable: {
    amount: '{amountSpent}',
    searchByDescription: 'Buscar por descripción',
    ruleDescription: 'Descripción de reglas"',
    points: 'Puntos',
    currencyAmount: 'Monto en la moneda correspondiente',
    skus: 'SKUs',
    editOn: 'Editado el',
    status: 'Status'
  },
  createRulePage: {
    title: 'Tipo de regla',
    description: 'Selecciona un tipo de acuerdo con los objetivos de creación de reglas.',
    earningRuleTitle: 'Ganancias',
    earningRuleDescription:
      'Esta regla permite que todos los POC registrados en el {tier} ganen puntos por compras elegibles.',
    exclusionRuleTitle: ' Exclusión',
    exclusionRuleDescription:
      'Esta regla impide que los POC acumulen puntos a través de las reglas de ganancia, a la vez que los mantienen en el {tier}.'
  },
  modal: {
    confirmLabel: 'Sí, salir',
    cancelLabel: 'No, volver',
    title: 'Eliminar regla?',
    body: 'No puedes deshacer esta acción.',
    deleteRule: {
      title: 'Eliminar regla?',
      body: 'No puedes deshacer esta acción.',
      confirm: 'Sí, eliminar'
    }
  },
  modalUpdate: {
    confirmLabel: 'Sí, guardar',
    cancelLabel: 'No, volver',
    title: '¿Guardar cambios?',
    body: 'Los cambios guardados se aplicarán a la regla editada.'
  },
  validations: {
    emptyField: 'Ingrese un valor.',
    skuQuantityInvalid: 'El valor deberá ser mayor a 0.',
    invalidNumberValueType: 'Decimal numbers are not allowed.'
  },
  modalAddSku: {
    csvUploadInstruction: 'Cargar un archivo CSV para agregar varios SKU a la vez.'
  },
  createExclusionRules: {
    editRule: 'Editar regla'
  },
  createEarningRules: {
    title: 'Details',
    createNewEarningRule: 'Crear regla',
    goToAudiencesBtn: 'Ir a Audiencias',
    description: 'Description',
    points: 'Points',
    dollarAmount: 'Currency amount',
    categoryId: 'Category ID',
    createRule: 'Create',
    cancelRule: 'Cancelar',
    message: 'New rule has been created',
    ruleType: 'Tipo de regla',
    ruleTypeDescription: 'Selecciona un tipo de acuerdo con los objetivos de creación de reglas.',
    earning: 'Ganancias',
    earningDescription:
      'Esta regla permite que todos los POC registrados en el {{tier}} ganen puntos por compras elegibles.',
    exclusion: 'Exclusión',
    goBack: 'Volver',
    continue: 'Continuar',
    add: 'Agregar',
    leaveRuleCreationModal: {
      title: '¿Deseas dejar la creación de reglas?',
      message: 'Se perderán los cambios no guardados.',
      confirm: 'Sí, salir',
      cancel: 'No, volver'
    },
    earningSection: {
      rule: 'Regla',
      settings: 'Configuración',
      settingsDescription: 'Establecer los parámetros de la nueva regla.',
      amountOfPoints: 'Cantidad de puntos',
      enterPoints: 'Ingresar puntos',
      currencyAmount: 'Monto en la moneda correspondiente',
      enterCurrencyAmount: 'Ingresar el monto en la moneda correspondiente',
      ruleDescription: 'Descripción de reglas',
      enterRuleDescription: 'Ingresar descripción de reglas',
      skus: 'SKUs',
      skusDescription:
        'Agregar los SKU de productos que recompensarán a los POC con puntos en virtud de esta regla.',
      skuSearchPlaceholder: 'Buscar por nombre de producto o SKU',
      preview: 'Vista previa',
      product: 'Producto',
      sku: 'SKU',
      addSkus: 'Agregar SKU',
      skuEmptyTableMessage:
        'Los detalles del producto estarán disponibles después de agregar los SKU.',
      saveAndExit: 'Guardar y salir',
      createRule: 'Crear regla',
      audienceDescription:
        'Seleccionar la audiencia de POC que no podrán ganar puntos en compras elegibles.',
      addSkusSection: {
        manuallyAdd: 'Agregar manualmente',
        searchAndAddSkus: 'Buscar y agregar los SKU.',
        uploadCsv: 'Cargar CSV',
        addMultiples: 'Agregar múltiples SKU a la vez.',
        searchBySkus: 'Buscar por SKU',
        search: 'Buscar',
        searchResultsMessage:
          'Los resultados de la búsqueda no mostrarán los SKU que estén por vencer.',
        searchEmptyMessage: 'Utilizar la búsqueda para encontrar los SKU que deseas agregar.',
        uploadCsvMessage: 'Cargar un archivo CSV para agregar varios SKU a la vez.',
        downloadCsv: 'Descargar plantilla CSV',
        uploadFile: 'Cargar un archivo',
        upload: 'Cargar'
      }
    },
    createEarningRulesModal: {
      title: 'Crear regla?',
      message: 'Una vez creada, esta regla se activará.',
      cancel: 'No, volver',
      confirm: 'Sí, crear'
    },
    createRuleErrorMessage: 'Se produjo un error. Inténtalo de nuevo.',
    createRuleDraftSuccessfulMessage: 'La regla se guardó correctamente como borrador.',
    createRuleSuccessfulMessage: 'Regla creada correctamente.',
    editedRuleSuccessfulMessage: 'Regla editada correctamente.',
    deletedRuleSuccessfulMessage: 'Regla eliminada correctamente.',
    editRule: 'Editar regla',
    save: 'Guardar',
    edit: 'Editar',
    deleteRule: 'Eliminar regla'
  },
  skus: {
    list: {
      skus: 'SKUs',
      addSkuButton: '+ ADD SKU',
      editSkuButton: 'Edit SKU',
      searchSkus: 'Search SKUs',
      columnPreview: 'Vista previa',
      columnProduct: 'Producto',
      columnSku: 'SKU',
      columnSkuName: 'SKU Name',
      actionChange: 'Change Rule',
      actionRemove: 'Remove',
      emptyMessage: 'No items have been added',
      search: 'Buscar por nombre de producto o SKU',
      searchBtn: 'Buscar'
    },
    removeDialogTitle: 'Remove SKU',
    removeDialogMessage:
      'Are you sure you want to remove this SKU? This will be removed immediately from the app. ',
    notification: {
      successChangeRule: 'SKU earning rule has been changed',
      successEditRule: 'Earning rule has been edited',
      errorChangeRule: 'Something went wrong. Please try again later.',
      remove: {
        success: 'SKU has been removed',
        error: 'Something went wrong. Please try again later.'
      },
      addSku: {
        success: 'SKU has been added',
        error: 'Something went wrong. Please try again later.'
      }
    }
  },
  steps: {
    action: {
      label: 'Action',
      sublabel: 'Select action',
      add: {
        label: 'Add SKU',
        sublabel: "Add SKU's to the rule"
      },
      move: {
        label: 'Move SKU',
        sublabel: "Move SKU's from this rule to other"
      },
      remove: {
        label: 'Remove SKU',
        sublabel: "Remove SKU's from this rule",
        expiringItemsMessage: 'Items close to expiring will not be show in challneges'
      }
    },
    process: {
      label: 'Process',
      sublabel: 'Select the update process',
      single: {
        label: 'Agregar manualmente',
        sublabel: 'Buscar y agregar los SKU.'
      },
      multiple: {
        label: 'Cargar CSV',
        sublabel: 'Agregar múltiples SKU a la vez.'
      }
    },
    skus: {
      label: "SKU's",
      sublabel: "Select SKU's",
      expiringItemsMessage:
        'Los resultados de la búsqueda no mostrarán los SKU que estén por vencer.'
    }
  },
  errorMessage: {
    emptyField: 'Ingrese un valor.',
    exceedsMaxSize1mb: 'exceeds the maximum size of 1MB.',
    unsupportedFileFormat: 'has an unsupported file format.',
    uploadFailed: '{{name}} upload failed, upload it again.'
  },
  button: {
    upload: 'Subir',
    uploadCsvFile: 'Subir un archivo',
    downloadCsvTemplate: 'Descargar template CSV'
  },
  emptySkuContainer: 'Utilizar la búsqueda para encontrar los SKU que deseas agregar.',
  noResults: 'No results',
  noResultsMessage: 'We could not find find what you are looking for.',
  toastDeleteRule: {
    success: 'Regra excluída com sucesso.',
    error: 'Ocorreu um erro. Tente novamente.'
  }
};

export default esES;
