import { createContext, useContext, useMemo, useState } from 'react';
import { DialogContextProps, DialogCustomProps, DialogProviderProps } from './DialogContext.types';
import DialogCustom from './components/DialogCustom/DialogCustom';

export const DialogContext = createContext<DialogContextProps>({} as DialogContextProps);

export const useDialogContext = () => useContext(DialogContext);

const initStateDialog: DialogCustomProps = {
  open: false,
  title: '',
  body: '',
  noMarginBottom: false
};
const DialogProvider = ({ children }: DialogProviderProps) => {
  const [dialogData, setDialogData] = useState<DialogCustomProps>(initStateDialog);

  const resetDialog = () => setDialogData(initStateDialog);

  const valueMemo = useMemo(() => ({ showDialog: setDialogData, resetDialog }), [setDialogData]);

  return (
    <DialogContext.Provider value={valueMemo}>
      {children}
      {dialogData.open && <DialogCustom dialogData={dialogData} resetDialog={resetDialog} />}
    </DialogContext.Provider>
  );
};

export default DialogProvider;
