import { Button, Dialog, Heading } from '@hexa-ui/components';

import { DialogCustomProps } from '../../DialogContext.types';
import { SParagraph, WrapperDialogButtons } from './DialogCustom.styles';

interface DialogCustomComponentProps {
  dialogData: DialogCustomProps;
  resetDialog: () => void;
}

const { Close, Root } = Dialog;

const DialogCustom = ({ dialogData, resetDialog }: DialogCustomComponentProps) => {
  const { body, title, buttonLeft, buttonRight, closeButton, noMarginBottom } = dialogData;
  const onClick = closeButton?.onClick;
  const marginBottom = noMarginBottom ? { marginBottom: '0px' } : {};

  const dialogTitle = <Heading size="H2">{title}</Heading>;

  return (
    <Root
      contentCss={marginBottom}
      headerHeight="auto"
      open
      onClose={onClick ?? resetDialog}
      title={dialogTitle}
      actions={
        <WrapperDialogButtons>
          {buttonLeft && (
            <Close>
              <Button
                data-testid="left-button-dialog"
                css={buttonRight && { marginRight: '0.9375rem' }}
                size="medium"
                {...buttonLeft}
              />
            </Close>
          )}
          {buttonRight && (
            <Close>
              <Button data-testid="right-button-dialog" size="medium" {...buttonRight} />
            </Close>
          )}
        </WrapperDialogButtons>
      }
    >
      {typeof body === 'string' ? <SParagraph>{body}</SParagraph> : body}
    </Root>
  );
};

export default DialogCustom;
