import languages from '../config/data/countries.json';
import storeSymbols from '../config/data/currency_symbols.json';

interface CountryConfig {
  locale: string;
  currency: string;
}

export const OPTIMIZELY_PROJECT_NAME = 'BEES - Rewards Admin Service';

/**
 * Default date locale code used on date formatting
 */
export const DEFAULT_DATE_LOCALE_CODE = 'en';

/**
 * Create Rules Permission
 */
export const CREATE_EARNING_RULES = 'Membership.CreateEarningRules.Delete';
export const CREATE_EXCLUSION_LIST = 'Membership.CreateExclusionList.Delete';
/**
 * Maximum decimal value size
 */
export const MAX_DECIMAL_SIZE = 6;

/**
 * Maximum integer value size
 */
export const MAX_INTEGER_SIZE = 10;

/**
 * Available countries configuration (locale, currency...)
 */
export const COUNTRY_CONFIG: Record<string, CountryConfig> = languages;

/**
 *  Currency symbols for each country
 */
export const CORRENCY_SYMBOLS: Record<string, string> = storeSymbols;

/**
 * Scope authorization keys
 */
export const SCOPE_AUTHORIZATION_KEYS = {
  CREATE_EARNING_RULES: 'Membership.CreateEarningRules.Delete',
  CREATE_EXCLUSION_LIST: 'Membership.CreateExclusionList.Delete'
};

export const ONLY_NUMBER_REGEX = /^\d*$/;
