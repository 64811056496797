import { useEffect, useState } from 'react';

declare global {
  interface Window {
    Cypress?: any;
  }
}

const MAX_RETRIES = 500;
const MEMBERSHIP_MFE_ID = 'single-spa-application:@admin-portal/membership-beta-mfe';

export default function useMembershipMfeIsLoaded() {
  const [isLoadingMembershipMfe, setIsLoadingMembershipMfe] = useState(true);

  function isMembershipLoaded(retryCount = 0) {
    const membershipContent = !window.Cypress
      ? document.getElementById(MEMBERSHIP_MFE_ID).innerHTML.length
      : 1;

    if (membershipContent === 0 && retryCount < MAX_RETRIES) {
      requestAnimationFrame(() => isMembershipLoaded(retryCount + 1));
      return;
    }
    if (retryCount >= MAX_RETRIES) {
      window.location.href = '/membership/challenges';
      return;
    }

    setIsLoadingMembershipMfe(false);
  }

  useEffect(() => isMembershipLoaded(), []);

  return {
    isLoadingMembershipMfe
  };
}
