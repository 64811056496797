import MessageMap from '../i18n.d';

const ptBR: MessageMap = {
  test: {
    translate: 'Traduzir',
    missing: 'Disponível apenas a opção em inglês'
  },
  breadcrumb: {
    home: 'Lista de regras',
    create: 'Criar regra',
    earning: 'Ganhos',
    exclusion: 'Exclusão'
  },
  buttons: {
    back: 'Voltar',
    continue: 'Continuar',
    createNewRuleBtn: 'Criar regra',
    goToAudiencesBtn: 'Ir para Públicos'
  },
  rules: {
    title: 'Lista de regras',
    earningList: 'Lista de ganhos',
    earningName: '{point} points for every {amountSpent} spent',
    earningRulesDescription:
      'Essas regras permitem que todos os PDVs registrados no {tier} ganhem pontos por compras qualificadas.',

    exclusionList: 'Lista de exclusão',
    exclusionListDescription:
      'Essas regras impedem que os PDVs acumulem pontos por meio de regras de ganho, enquanto permanecem no {tier}.'
  },

  modal: {
    confirmLabel: 'Sim, sair',
    cancelLabel: 'Não, voltar',
    title: 'Sair da criação da regra?',
    body: 'Todas as alterações não salvas serão perdidas.',
    deleteRule: {
      title: 'Excluir regra?',
      body: 'Você não pode desfazer esta ação.',
      confirm: 'Sim, excluir'
    }
  },
  modalUpdate: {
    confirmLabel: 'Sim, salvar',
    cancelLabel: 'Não, voltar',
    title: 'Salvar alterações?',
    body: 'As alterações salvas serão aplicadas à regra editada.'
  },
  tablesRules: {
    searchByDescription: 'Pesquisar por descrição',
    ruleDescription: 'Descrição da regra',
    editedOn: 'Editado em',
    status: 'Status',
    draft: 'Rascunho',
    active: 'Ativo',
    audience: 'Público',
    audienceName: 'Nome do Público',
    estimatedPOCsTargeted: 'POCs alvo estimados'
  },
  earningRulesTable: {
    amount: '{amountSpent}',
    searchByDescription: 'Pesquisar por descrição',
    ruleDescription: 'Descrição da regra',
    points: 'Pontos',
    currencyAmount: 'Valor da moeda',
    skus: 'SKUs',
    editOn: 'Editado em',
    status: 'Status'
  },
  createRulePage: {
    title: 'Tipo de regra',
    description: 'Selecione um tipo de acordo com as metas de criação de regras.',
    earningRuleTitle: 'Ganhos',
    earningRuleDescription:
      'Essa regra permite que todos os PDVs registrados no {tier} ganhem pontos por compras qualificadas.',
    exclusionRuleTitle: 'Exclusão',
    exclusionRuleDescription:
      'Essa regra impede que os PDVs acumulem pontos por meio de regras de ganho, enquanto permanecem no {tier}.'
  },
  validations: {
    emptyField: 'Insira um valor.',
    skuQuantityInvalid: 'O valor dever ser maior do que 0.',
    invalidNumberValueType: 'Decimal numbers are not allowed.'
  },
  modalAddSku: {
    csvUploadInstruction: 'Carregue um arquivo CSV para adicionar vários SKUs de uma vez.'
  },
  createExclusionRules: {
    editRule: 'Editar regra'
  },
  createEarningRules: {
    title: 'Details',
    createNewEarningRule: 'Criar regra',
    goToAudiencesBtn: 'Ir para Públicos',
    description: 'Description',
    points: 'Points',
    dollarAmount: 'Currency amount',
    categoryId: 'Category ID',
    createRule: 'Create',
    cancelRule: 'Cancelar',
    message: 'New rule has been created',
    ruleType: 'Tipo de regra',
    ruleTypeDescription: 'Selecione um tipo de acordo com as metas de criação de regras.',
    earning: 'Ganhos',
    earningDescription:
      'Essa regra permite que todos os PDVs registrados no {{tier}} ganhem pontos por compras qualificadas.',
    exclusion: 'Exclusão',
    goBack: 'Voltar',
    continue: 'Continuar',
    add: 'Adicionar',
    leaveRuleCreationModal: {
      title: 'Sair da criação da regra?',
      message: 'Todas as alterações não salvas serão perdidas.',
      confirm: 'Sim, sair',
      cancel: 'Não, voltar'
    },
    earningSection: {
      rule: 'Regra',
      settings: 'Configurações',
      settingsDescription: 'Defina os parâmetros da nova regra.',
      amountOfPoints: 'Quantidade de pontos',
      enterPoints: 'Inserir pontos',
      currencyAmount: 'Valor monetário',
      enterCurrencyAmount: 'Inserir valor monetário',
      ruleDescription: 'Descrição da regra',
      enterRuleDescription: 'Inserir descrição da regra',
      skus: 'SKUs',
      skusDescription:
        'Adicione SKUs de produtos que recompensarão PDVs com pontos de acordo com esta regra.',
      skuSearchPlaceholder: 'Pesquisar por nome do produto ou SKU',
      preview: 'Mostrar prévia',
      product: 'Produto',
      sku: 'SKU',
      addSkus: 'Adicionar SKUs',
      skuEmptyTableMessage: 'Os detalhes do produto ficarão disponíveis após adicionar os SKUs.',
      saveAndExit: 'Salvar e sair',
      createRule: 'Criar regra',
      audienceDescription:
        'Selecione o público de PDVs que não poderão ganhar pontos em compras qualificadas.',
      addSkusSection: {
        manuallyAdd: 'Adicionar manualmente',
        searchAndAddSkus: 'Pesquisar e adicionar SKUs.',
        uploadCsv: 'Carregar CSV',
        addMultiples: 'Adicionar vários SKUs de uma vez.',
        searchBySkus: 'Pesquisar por SKU',
        search: 'Pesquisar',
        searchResultsMessage:
          'Os resultados da pesquisa não mostrarão SKUs que estão prestes a expirar.',
        searchEmptyMessage: 'Use a pesquisa para encontrar SKUs que deseja adicionar.',
        uploadCsvMessage: 'Carregue um arquivo CSV para adicionar vários SKUs de uma vez.',
        downloadCsv: 'Baixar modelo de CSV',
        uploadFile: 'Carregar um arquivo',
        upload: 'Carregar'
      }
    },
    createEarningRulesModal: {
      title: 'Criar regra?',
      message: 'Uma vez criada, esta regra se torna ativa.',
      cancel: 'Não, voltar',
      confirm: 'Sim, criar'
    },
    createRuleErrorMessage: 'Ocorreu um erro. Tente novamente..',
    createRuleDraftSuccessfulMessage: 'Regra salva com sucesso como rascunho.',
    createRuleSuccessfulMessage: 'Regra criada com sucesso.',
    editedRuleSuccessfulMessage: 'Regra editada com sucesso.',
    deletedRuleSuccessfulMessage: 'Regra excluída com sucesso.',
    editRule: 'Editar regra',
    save: 'Salvar',
    edit: 'Editar',
    deleteRule: 'Excluir regra'
  },
  skus: {
    list: {
      skus: 'SKUs',
      addSkuButton: '+ ADD SKU',
      editSkuButton: 'Editar SKU',
      searchSkus: 'Search SKUs',
      columnPreview: 'Mostrar prévia',
      columnProduct: 'Produto',
      columnSku: 'SKU',
      columnSkuName: 'SKU Name',
      actionChange: 'Change Rule',
      actionRemove: 'Remove',
      emptyMessage: 'No items have been added',
      search: 'Pesquisar por nome do produto ou SKU',
      searchBtn: 'Buscar'
    },
    removeDialogTitle: 'Remove SKU',
    removeDialogMessage:
      'Are you sure you want to remove this SKU? This will be removed immediately from the app. ',
    notification: {
      successChangeRule: 'SKU earning rule has been changed',
      successEditRule: 'Earning rule has been edited',
      errorChangeRule: 'Something went wrong. Please try again later.',
      remove: {
        success: 'SKU has been removed',
        error: 'Something went wrong. Please try again later.'
      },
      addSku: {
        success: 'SKU has been added',
        error: 'Something went wrong. Please try again later.'
      }
    }
  },
  steps: {
    action: {
      label: 'Action',
      sublabel: 'Select action',
      add: {
        label: 'Add SKU',
        sublabel: "Add SKU's to the rule"
      },
      move: {
        label: 'Move SKU',
        sublabel: "Move SKU's from this rule to other"
      },
      remove: {
        label: 'Remove SKU',
        sublabel: "Remove SKU's from this rule"
      }
    },
    process: {
      label: 'Process',
      sublabel: 'Select the update process',
      single: {
        label: 'Adicionar manualmente',
        sublabel: 'Pesquisar e adicionar SKUs.'
      },
      multiple: {
        label: 'Carregar CSV',
        sublabel: 'Adicionar vários SKUs de uma vez.'
      }
    },
    skus: {
      label: "SKU's",
      sublabel: "Select SKU's",
      expiringItemsMessage:
        'Os resultados da pesquisa não mostrarão SKUs que estão prestes a expirar.'
    }
  },
  errorMessage: {
    emptyField: 'Insira um valor.',
    exceedsMaxSize1mb: 'excede(m) o tamanho máximo de 1 MB.',
    unsupportedFileFormat: 'tem um formato de arquivo não aceito.',
    uploadFailed: '{{name}} upload failed, upload it again.'
  },
  button: {
    upload: 'Carregar',
    uploadCsvFile: 'Carregar arquivo',
    downloadCsvTemplate: 'Baixar template CSV'
  },
  emptySkuContainer: 'Use a pesquisa para encontrar SKUs que deseja adicionar.',
  noResults: 'No results',
  noResultsMessage: 'We could not find find what you are looking for.',
  toastDeleteRule: {
    success: 'Rule successfully deleted.',
    error: 'An error occurred. Try again.'
  }
};

export default ptBR;
