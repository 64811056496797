import { createFeatureToggleServiceV2 } from 'admin-portal-shared-services';
import { OPTIMIZELY_PROJECT_NAME } from '../constants';

let initializedToggles = false;

export function initFeatureToggles(optimizelyKey: string): void {
  if (initializedToggles) {
    return;
  }

  createFeatureToggleServiceV2(OPTIMIZELY_PROJECT_NAME, optimizelyKey || 'localhost');
  initializedToggles = true;
}
