import MessageMap from '../i18n.d';

const enUS: MessageMap = {
  test: {
    translate: 'Translate',
    missing: 'Only English option available'
  },
  breadcrumb: {
    home: 'Rules list',
    create: 'Create Rule',
    earning: 'Earning',
    exclusion: 'Exclusion'
  },
  buttons: {
    createNewRuleBtn: 'Create rule',
    goToAudiencesBtn: 'Go to Audiences',
    back: 'Go back',
    continue: 'Continue'
  },
  rules: {
    title: 'Rules list',
    earningName: '{point} points for every {amountSpent} spent',
    earningList: 'Earning list',
    earningRulesDescription:
      'These rules allow all POCs registered in {tier} to earn points by eligible purchases.',

    exclusionList: 'Exclusion list',
    exclusionListDescription:
      'These rules prevent POCs from accumulating points through earning rules, while keeping them in {tier}.'
  },
  tablesRules: {
    searchByDescription: 'Search by description',
    ruleDescription: 'Rule description',
    editedOn: 'Edited on',
    status: 'Status',
    draft: 'Draft',
    active: 'Active',
    audience: 'Audience',
    audienceName: 'Audience name',
    estimatedPOCsTargeted: 'Estimated POCs targeted'
  },
  earningRulesTable: {
    amount: '{amountSpent}',
    searchByDescription: 'Search by description',
    ruleDescription: 'Rule description',
    points: 'Points',
    currencyAmount: 'Currency amount',
    skus: 'SKUs',
    editOn: 'Edited on',
    status: 'Status'
  },
  createRulePage: {
    title: 'Rule type',
    description: 'Select a type according to the goals of rule creation.',
    earningRuleTitle: 'Earning',
    earningRuleDescription:
      'This rule allows all POCs registered in {tier} to earn points by eligible purchases.',
    exclusionRuleTitle: ' Exclusion',
    exclusionRuleDescription:
      'This rule prevents POCs from accumulating points through earning rules, while keeping them in {tier}.'
  },
  modal: {
    confirmLabel: 'Yes, leave',
    cancelLabel: 'No, go back',
    title: 'Leave rule creation?',
    body: 'All unsaved changes will be lost.',
    deleteRule: {
      title: 'Delete rule?',
      body: 'You cannot undo this action.',
      confirm: 'Yes, delete'
    }
  },
  modalUpdate: {
    confirmLabel: 'Yes, save',
    cancelLabel: 'No, go back',
    title: 'Save changes?',
    body: 'Saved changes will apply to the edited rule.'
  },
  validations: {
    emptyField: 'Please enter a value.',
    skuQuantityInvalid: 'Value must be greater than 0',
    invalidNumberValueType: 'Decimal numbers are not allowed.'
  },
  modalAddSku: {
    csvUploadInstruction: 'Upload a CSV file to add multiple SKUs at once.'
  },
  createExclusionRules: {
    editRule: 'Edit rule'
  },
  createEarningRules: {
    title: 'Details',
    createNewEarningRule: 'Create rule',
    goToAudiencesBtn: 'Go to Audiences',
    description: 'Description',
    points: 'Points',
    dollarAmount: 'Currency amount',
    categoryId: 'Category ID',
    createRule: 'Create',
    cancelRule: 'Cancel',
    message: 'New rule has been created',
    ruleType: 'Rule type',
    ruleTypeDescription: 'Select a type according to the goals of rule creation.',
    earning: 'Earning',
    earningDescription:
      'This rule allows all POCs registered in {{tier}} to earn points by eligible purchases.',
    exclusion: 'Exclusion',
    goBack: 'Go back',
    continue: 'Continue',
    add: 'Add',
    leaveRuleCreationModal: {
      title: 'Leave rule creation?',
      message: 'All unsaved changes will be lost.',
      confirm: 'Yes, leave',
      cancel: 'No, go back'
    },
    earningSection: {
      rule: 'Rule',
      settings: 'Settings',
      settingsDescription: 'Set the parameters of the new rule.',
      amountOfPoints: 'Amount of points',
      enterPoints: 'Enter points',
      currencyAmount: 'Currency amount',
      enterCurrencyAmount: 'Enter currency amount',
      ruleDescription: 'Rule description',
      enterRuleDescription: 'Enter rule description',
      skus: 'SKUs',
      skusDescription: 'Add product SKUs that will reward POCs with points under this rule.',
      skuSearchPlaceholder: 'Search by product name or SKU',
      preview: 'Preview',
      product: 'Product',
      sku: 'SKU',
      addSkus: 'Add SKUs',
      skuEmptyTableMessage: 'Product details will become available after adding SKUs.',
      saveAndExit: 'Save and exit',
      createRule: 'Create rule',
      audienceDescription:
        'Select the audience of POCs that will not be allowed to earn points on eligible purchases.',
      addSkusSection: {
        manuallyAdd: 'Manually add',
        searchAndAddSkus: 'Search and add SKUs.',
        uploadCsv: 'Upload CSV',
        addMultiples: 'Add multiple SKUs at once.',
        searchBySkus: 'Search by SKU',
        search: 'Search',
        searchResultsMessage: 'Search results will not show SKUs that are close to expiring.',
        searchEmptyMessage: 'Use the search to find SKUs you want to add.',
        uploadCsvMessage: 'Upload a CSV file to add multiple SKUs at once.',
        downloadCsv: 'Download CSV template',
        uploadFile: 'Upload a file',
        upload: 'Upload'
      }
    },
    createEarningRulesModal: {
      title: 'Create rule?',
      message: 'Once created, this rule becomes active.',
      cancel: 'No, go back',
      confirm: 'Yes, create'
    },
    createRuleErrorMessage: 'An error occurred. Try again.',
    createRuleDraftSuccessfulMessage: 'Rule successfully saved as draft.',
    createRuleSuccessfulMessage: 'Rule successfully created.',
    editedRuleSuccessfulMessage: 'Rule successfully edited.',
    deletedRuleSuccessfulMessage: 'Rule successfully deleted.',
    editRule: 'Edit rule',
    save: 'Save',
    edit: 'Edit',
    deleteRule: 'Delete rule'
  },
  skus: {
    list: {
      skus: 'SKUs',
      addSkuButton: '+ ADD SKU',
      editSkuButton: 'Edit SKU',
      searchSkus: 'Search SKUs',
      columnPreview: 'Preview',
      columnProduct: 'Product',
      columnSku: 'SKU',
      columnSkuName: 'SKU Name',
      actionChange: 'Change Rule',
      actionRemove: 'Remove',
      emptyMessage: 'No items have been added',
      search: 'Search by product name or SKU',
      searchBtn: 'Search'
    },
    removeDialogTitle: 'Remove SKU',
    removeDialogMessage:
      'Are you sure you want to remove this SKU? This will be removed immediately from the app. ',
    notification: {
      successChangeRule: 'SKU earning rule has been changed',
      successEditRule: 'Earning rule has been edited',
      errorChangeRule: 'Something went wrong. Please try again later.',
      remove: {
        success: 'SKU has been removed',
        error: 'Something went wrong. Please try again later.'
      },
      addSku: {
        success: 'SKU has been added',
        error: 'Something went wrong. Please try again later.'
      }
    }
  },
  steps: {
    action: {
      label: 'Action',
      sublabel: 'Select action',
      add: {
        label: 'Add SKU',
        sublabel: "Add SKU's to the rule"
      },
      move: {
        label: 'Move SKU',
        sublabel: "Move SKU's from this rule to other"
      },
      remove: {
        label: 'Remove SKU',
        sublabel: "Remove SKU's from this rule"
      }
    },
    process: {
      label: 'Process',
      sublabel: 'Select the update process',
      single: {
        label: 'Manually add',
        sublabel: 'Search and add SKUs.'
      },
      multiple: {
        label: 'Upload CSV',
        sublabel: 'Add multiple SKUs at once.'
      }
    },
    skus: {
      label: "SKU's",
      sublabel: "Select SKU's",
      expiringItemsMessage: 'Search results will not show SKUs that are close to expiring.'
    }
  },
  errorMessage: {
    emptyField: 'Please enter a value.',
    exceedsMaxSize1mb: 'exceeds the maximum size of 1MB.',
    unsupportedFileFormat: 'has an unsupported file format.',
    uploadFailed: '{name} upload failed, upload it again.'
  },
  button: {
    upload: 'Upload',
    uploadCsvFile: 'Upload file',
    downloadCsvTemplate: 'Download CSV Template'
  },
  emptySkuContainer: 'Use the search to find SKUs you want to add.',
  noResults: 'No results',
  noResultsMessage: 'We could not find find what you are looking for.',
  toastDeleteRule: {
    success: 'Rule successfully deleted.',
    error: 'An error occurred. Try again.'
  }
};

export default enUS;
